import React, { useState } from "react"
import { Carousel } from 'react-responsive-carousel'
import { PrimaryBtn } from "components/library"
import { Card, CardProps } from "./hero-card"
import Accelerate from "images/illus_solutions_research.png"
import Organise from "images/illus_solutions_organise.png"
import Analyse from "images/illus_forecast_no_blob.png"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

const features: CardProps[] = [
	{
		label: "common.words.accerlerate",
		desc: "researchers-page.hero.features.1.desc",
		image: Accelerate,
	},
	{
		label: "common.words.organise",
		desc: "researchers-page.hero.features.2.desc",
		image: Organise,
	},
	{
		label: "common.words.analyse",
		desc: "researchers-page.hero.features.3.desc",
		image: Analyse,
	},
]

export const ResearchersHero = () => {
	const [slide, setslide] = useState(0)
	const { t } = useTranslation()

	return (
		<section className="flex flex-col gap-20 items-center">
			<div className="flex flex-col gap-6">
				<span className="text-4xl tracking-wider sm:text-5xl md:text-7xl bg-gradient-to-r from-theme-blue to-theme-orange bg-clip-text text-transparent">
					{t("researchers-page.hero.heading")}
				</span>

				<p className="sm:text-lg sm:max-w-xl md:text-2xl">
					<Trans
						i18nKey="researchers-page.hero.description"
						components={{
							anchor: <a className="anchor" />,
						}}
					/>
				</p>

				<PrimaryBtn
					className="md:text-lg max-w-max"
					onClick={() => window.requestADemo()}
				>
					{t("common.cta.demo")}
				</PrimaryBtn>
			</div>

			<div className="hidden sm:grid grid-cols-3 gap-8 w-full text-left">
				{features.map((feature) => (
					<Card key={feature.label} {...feature} />
				))}
			</div>

			<div className="text-center flex sm:hidden w-full flex-col gap-6 text-base">
				<Carousel
					selectedItem={slide}
					onChange={setslide}>
					{features.map(feature => <Card key={feature.label} {...feature} />)}
				</Carousel>
			</div>
		</section>
	)
}
